import { useState, useEffect } from 'react';
import { Button, Form, Input, Space, Modal, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import "./index.less";
import { post } from "../../../utils/axios"
import { useAppDispatch } from '../../../store/hook'
import { setAuthInfo, delAuthInfo, setUserInfo } from '../../../store/slices/currentUser'
import PubSub from 'pubsub-js'

type VideoItem = {
    name: string
    url: string
}

export default () => {
    const [loading, setLoading] = useState(false) // 是否登录中
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    // 提交登录表单
    const onFinish = (values: any) => {
        const { username, password } = values

        setLoading(true)
        post({
            url: '/Token/GetToken',
            params: {
                username, password
            }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const token = res.Data.token
                    // 本地存储token
                    dispatch(setAuthInfo({ token, time: Date.now() }))
                    // 获取用户信息
                    return post({
                        url: 'Employee/GetCurUserInfo',
                        params: { token }
                    })
                } else {
                    notification.error({
                        message: '提示',
                        description: res.Msg,
                    })
                    form.resetFields()
                    return Promise.reject('获取token失败');
                }
            })
            .then(res => {
                if (res.IsSuccess) {
                    // 存储登录用户信息
                    dispatch(setUserInfo({ EmployeeName: res.Data.EmployeeName }))
                    // 跳转到首页
                    navigate('/')
                    // 发送用户登录事件
                    PubSub.publish('UserLoginEvent')
                } else {
                    notification.error({
                        message: '提示',
                        description: '登录失败',
                    })
                    // 获取失败，清除本地token
                    dispatch(delAuthInfo())
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="login-page">
            <div className='login-form__wrap'>
                <div className='form-header'></div>

                <Form
                    form={form}
                    className='form-login'
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '用户名不能为空' }]}
                    >
                        <Input prefix={<i className='fm-icon-user' />} bordered={false} placeholder='请输入用户名' />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '密码不能为空' }]}
                    >
                        <Input.Password prefix={<i className='fm-icon-lock' />} bordered={false} placeholder='请输入密码' autoComplete="off" />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" htmlType="submit" block loading={loading}>登录</Button>
                    </Form.Item>
                </Form>

                <Space
                    className='form-footer'
                    direction="vertical"
                    align="center"
                    size="middle"
                >
                    <a href="http://ehr.zgproperty.cn:8008/" target="_blank">
                        工号查询 <i className='fm-icon-enter'></i>
                    </a>
                    <a onClick={() => setIsModalVisible(true)}>
                        入门操作视频 <i className='fm-icon-enter'></i>
                    </a>
                </Space>
            </div>

            {/* 视频播放器 */}
            <VideoPlayer visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </div >
    );
};

// 视频播放器
const VideoPlayer = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
    const [playList, setPlayList] = useState<VideoItem[]>([
        {
            name: '1.内部门户系统介绍',
            url: 'https://zgwybucket-1253656920.file.myqcloud.com/video/门户登录视频/oa介绍部分.mp4'
        },
        {
            name: '2.电脑端入门视频',
            url: 'https://zgwybucket-1253656920.file.myqcloud.com/video/门户登录视频/电脑端登录.mp4'
        },
        {
            name: '3.移动端入门视频',
            url: 'https://zgwybucket-1253656920.file.myqcloud.com/video/门户登录视频/手机端登录.mp4'
        },
    ])
    const [currentVideo, setCurrentVideo] = useState<VideoItem>()

    useEffect(() => {
        setCurrentVideo(playList[0])
    }, [])

    return (
        <Modal
            className='video-play-modal'
            title="视频列表"
            width="65%"
            visible={visible}
            footer={null}
            destroyOnClose={true}
            onCancel={onClose}
        >
            <div className='container'>
                <ul className='play-list'>
                    {
                        playList.map(item => (
                            <li
                                key={item.name}
                                className={currentVideo?.name === item.name ? 'play-list__cell h-ellipsis-1 active' : 'play-list__cell h-ellipsis-1'}
                                onClick={() => setCurrentVideo(item)}
                            >
                                <i className='fm-icon-play'></i>{item.name}
                            </li>
                        ))
                    }
                </ul>

                <video
                    className='play-video'
                    src={currentVideo?.url}
                    controls
                    controlsList="nodownload"
                    onContextMenu={(e) => {
                        // 禁止右键菜单
                        e.preventDefault()
                        return false
                    }}
                ></video>
            </div>
        </Modal >
    )
}