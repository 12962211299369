import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { post } from "../../../../utils/axios"
import moment from 'moment';

type NoticeItem = {
    ObjectID: string
    Url: string
    fbsj: string
    titel: string
    showType: string
}

export default ({ itemClick }: { itemClick: (type: string, url: string) => void }) => {
    const [activeIndex, setActiveIndex] = useState(0) // 标签页选中项
    const [noticeList, setNoticeList] = useState<NoticeItem[]>([])
    const noticeType = ['物业板块', '御科板块']

    useEffect(() => {
        getNoticeList(noticeType[0])
    }, [])

    // 获取公告列表
    const getNoticeList = (type: string) => {
        post({
            url: 'BpmQuery/QueryNoticeList',
            data: { NoticeType: type, PageSize: 7 }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data.List as NoticeItem[]
                    setNoticeList(data.map(item => {
                        item.fbsj = moment(item.fbsj).format('YYYY-MM-DD')
                        return item
                    }))
                }
            })
    }

    // 点击标签页
    const handleTabClick = (index: number) => {
        setActiveIndex(index)
        getNoticeList(noticeType[index])
    }

    return (
        <section className="section-container section_1-1">
            <div className="section-header">
                <h2 className="title">通知公告</h2>

                <ul className="tabs">
                    <li className={activeIndex === 0 ? "tab active" : "tab"} onClick={() => handleTabClick(0)}>物业板块</li>
                    <li className={activeIndex === 1 ? "tab active" : "tab"} onClick={() => handleTabClick(1)}>御科板块</li>
                </ul>

                <Link to={'/notice'} state={{ noticeType: noticeType[activeIndex] }} className="more">更多&gt;&gt;</Link>
            </div>
            <div className="section-body">
                <ul className='list' style={{ 'display': noticeList.length > 0 ? 'block' : 'none' }}>
                    {
                        noticeList.map(item => (
                            <li className='item' key={item.ObjectID} onClick={() => itemClick(item.showType, item.Url)}>
                                <div className='title h-ellipsis-1'>{item.titel}</div>
                                <span className='date'>{item.fbsj}</span>
                            </li>
                        ))
                    }
                </ul>

                <Empty
                    style={{ 'display': noticeList.length > 0 ? 'none' : 'block' }}
                    description="暂无相关公告"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        </section>
    )
}