import { useState, useEffect } from 'react';
import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/lib/tree';
import { post } from "../../../../utils/axios"
import { downloadFile } from "../../../../utils"
import "./index.less";
import _find from 'lodash/find';

const { DirectoryTree } = Tree;

type NodeDataType = {
    key: string
    url: string
    fileName: string
}

export default () => {
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [nodeData, setNodeData] = useState<NodeDataType[]>([])

    useEffect(() => {
        getDocumentTreeData()
    }, [])

    // 获取文档树数据
    const getDocumentTreeData = () => {
        post({
            url: 'Document/GetDocumentTreeDatas'
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data.map((item: any) => creatTreeNode(item));
                    setTreeData(data)
                }
            })
    }

    // 创建树形节点
    const creatTreeNode = (node: any): DataNode => {
        if (node.IsMenu) {
            return {
                title: node.Name,
                key: node.Id,
                children: creatTreeMenuNode(node.Children),
                icon: (props) => {
                    if (props.expanded) {
                        return <i className='fm-icon-folder-open' style={{ "color": "#e7bc71" }}></i>
                    } else {
                        return <i className='fm-icon-folder-close' style={{ "color": "#e7bc71" }}></i>
                    }
                }
            }
        } else {
            setNodeData(data => ([
                ...data,
                {
                    key: node.Id,
                    url: node.Url,
                    fileName: node.DownLoadName
                }
            ]))
            return {
                icon: <i className='fm-icon-file' style={{ "color": "#e7bc71" }}></i>,
                title: (
                    <>
                        <span>{node.Name}</span>
                        <label style={{ "float": "right", "marginRight": "15px" }}>{node.CreateDate}</label>
                    </>
                ),
                key: node.Id,
            }
        }
    }

    // 创建树形父节点
    const creatTreeMenuNode = (children: any): DataNode[] => {
        const nodes: DataNode[] = []

        for (let i = 0; i < children.length; i++) {
            const node = children[i]
            nodes.push(creatTreeNode(node))
        }

        return nodes
    }

    // 点击树形节点
    const onSelect: TreeProps['onSelect'] = ([key]) => {
        const data = _find(nodeData, { 'key': key }) as NodeDataType
        if (data) {
            downloadFile(data.fileName, data.url)
        }
    }

    return (
        <div className="norm-page">
            <div className="norm-head">
                <div className="title">制度规范</div>
            </div>
            <div className="norm-body">
                <DirectoryTree
                    switcherIcon={<></>}
                    treeData={treeData}
                    onSelect={onSelect}
                />
            </div>
        </div>
    )
}