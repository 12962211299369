import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input, Space, Table, Divider, Breadcrumb, Checkbox, notification, Modal, Upload, Form, Radio, Row, Col, TreeSelect, Tree, InputRef } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import type { DataNode } from 'antd/es/tree';
import { HomeOutlined } from '@ant-design/icons';
import "./index.less";
import { post } from "../../../../utils/axios"
import { downloadFile } from "../../../../utils"
import qs from 'qs';
import moment from 'moment';
import _unionBy from 'lodash/unionBy';

type DirectoryItem = {
    FolderId: string // 文件夹 id
    FolderName: string // 文件夹名称
}

type FolderInfo = {
    FolderId: string // 文件夹 id
    IsCanUploadDoc: boolean // 是否能上传文档
    IsCanCreateFolder: boolean // 是否能新建文件夹
    IsCanDelete: boolean // 是否可删除
    IsCanPrevious: boolean // 是否返回上一级
    IsCanNext: boolean // 是否进入下一级
    FolderList: FolderItem[] // 当前文件夹信息
    DocPathList: DirectoryItem[] // 当前路径信息
}

type FolderItem = {
    Id: string // 文件夹、文档ID
    Name: string // 文件夹/文档名称
    FolderType: FolderType // 文件类型 1:文件夹 2:文档
    FolderTypeDesc: string // 文件类型文本
    Creator: string // 创建人
    Deletion: string // 删除人
    ModifyTime: string // 修改时间
    DeleteTime: string // 删除时间
    Path: string // 文档路径 (用于查看/下载)
    IsCanEdit: boolean // 当前文档是否可编辑
}

type FolderDetail = {
    FolderName: string // 文件夹名称
    FolderAuthState: number // 文件夹权限 1:所有人 2:指定人 3:仅管理员 4:默认
    folderId: string // 文件夹 id
    AuthDetailList: AuthDetailItem[]
}

type AuthDetailItem = {
    user: {
        userid: string // 用户id
        username: string // 用户名称
    }[]
    authType_u: boolean // 是否可上传
    authType_d: boolean // 是否可删除
    authType_c: boolean // 是否可创建
    authType_e: boolean // 是否可编辑
}

type AssignAuthItem = {
    text: string, // 权限名称
    flag: string, // 权限标识 [u,d,c,e] 对应 [上传,删除,创建,编辑]
    users: { 
        userid: string, 
        username: string 
    }[]
}

type OriginatorItem = {
    id: string
    text: string
    type: 'org' | 'user'
    selectable: Boolean
    children?: OriginatorItem[]
}

enum FolderType {
    directory = 1,
    file = 2,
}

const createOriginatorTree = (item: OriginatorItem): OriginatorItem => {
    if (item.type === 'org') {
        return {
            id: item.id,
            text: item.text,
            type: 'org',
            selectable: false,
            children: item.children?.map(node => createOriginatorTree(node)),
        }
    } else {
        return {
            id: item.id,
            text: item.text,
            type: 'user',
            selectable: true,
        }
    }
}

export default () => {
    const navigate = useNavigate()
    const { search } = useLocation();
    const [directoryList, setDirectoryList] = useState<DirectoryItem[]>([]) // 根目录列表
    const [activeDirectory, setActiveDirectory] = useState<string>() // 当前选中的目录
    
    useEffect(() => {
        getRootDirectory()
        
    }, [])

    // 获取根目录
    const getRootDirectory = () => {
        const { del_record, id } = qs.parse(search.slice(1))
        if (del_record) {
            setActiveDirectory('del_record')
        }

        post({
            url: '/ShareFolder/GetRootDirectory',
        })
        .then(res => {
            if (res.IsSuccess) {
                setDirectoryList(res.Data)

                const [firstDirectory] = res.Data as DirectoryItem[]
                if (!del_record && !id && firstDirectory) { // 如果当前不是打开历史删除记录, 则默认打开第一个根目录
                    navigate('/folder?id=' + firstDirectory.FolderId)
                }
            }
        })
    }

    return (
        <div className="folder-page">
            <aside className='menu'>
                {
                    directoryList.map(directory => (
                        <div 
                            key={directory.FolderId}
                            title={directory.FolderName}
                            className={directory.FolderId === activeDirectory ? "menu-item active" : "menu-item"}
                            onClick={() => {
                                navigate('/folder?id=' + directory.FolderId)
                                setActiveDirectory(directory.FolderId)
                            }}
                        >
                            <i className='fm-icon-home menu-item__icon' />
                            <span className='menu-item__name h-ellipsis-1'>{directory.FolderName}</span>
                        </div>
                    ))
                }

                <div 
                    className={activeDirectory === 'del_record' ? "menu-item active" : "menu-item"}
                    onClick={() => {
                        navigate('/folder?del_record=1')
                        setActiveDirectory('del_record')
                    }}
                >
                    <i className='fm-icon-delete menu-item__icon' />
                    <span className='menu-item__name'>历史删除记录</span>
                </div>
            </aside>

            <main className='content'>
                {
                    activeDirectory === 'del_record'
                    ?
                    <DeleteRecordPanel />
                    :
                    <FolderListPanel currentDirectoryId={id => setActiveDirectory(id)} />
                }
            </main>
        </div>
    )
}

// 文件夹列表
const FolderListPanel = ({currentDirectoryId}: {
    currentDirectoryId: (directoryId: string) => void // 返回当前文件夹的根目录 id
}) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const [loading, setLoading] = useState(false)
    const [folderInfo, setFolderInfo] = useState<FolderInfo>() // 当前文件夹信息
    const [folderList, setFolderList] = useState<FolderItem[]>([]) // 当前目录文件夹列表
    const folderAuthDetail = useRef<AuthDetailItem[]>([]) // 当前文件夹权限列表
    const [originatorTree, setOriginatorTree] = useState<OriginatorItem[]>([])
    const [selectRows, setSelectRows] = useState<string[]>([]) // 用户勾选的表格行 id
    const [activeRow, setActiveRow] = useState<string>() // 用户鼠标指向的表格行 id
    const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]) 
    const [keyword, setKeyword] = useState('') // 搜索关键词
    const [saveFolderModalController, setSaveFolderModalController] = useState<{
        visible: boolean, 
        folderId?: string
    }>({visible: false}) // 保存文件夹对话框控制器
    
    useEffect(() => {
        const { id } = qs.parse(search.slice(1))

        // 清空文件夹信息
        setFolderInfo(undefined)
        // 清空文件夹列表
        setFolderList([])
        // 清空表格选择行
        setSelectRows([])
        // 清空搜索关键词
        setKeyword('')

        if (id) {
            getFolderInfo(id.toString())
            getFolderDetail(id.toString())
        }
    }, [search])

    useEffect(() => {
        if (folderInfo) {
            const [directory] = folderInfo.DocPathList
            currentDirectoryId(directory.FolderId)
            getOriginatorTree()
        }
    }, [folderInfo])

    // 获取组织机构及用户信息
    const getOriginatorTree = () => {
        post({
            url: 'BpmQuery/GetOrgAndUserTree',
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data: OriginatorItem[] = res.Data.List.map((item: any) => createOriginatorTree(item))
                    setOriginatorTree(data)
                }
            })
    }

    // 获取文件夹信息
    const getFolderInfo = (id: string) => {
        setLoading(true)
        post({
            url: '/ShareFolder/GetFolderInfoByDocId',
            data: { FolderId: id }
        })
        .then(res => {
            if (res.IsSuccess) {
                setFolderInfo(res.Data)
                setFolderList(res.Data.FolderList)
            }
        })
        .finally(() => setLoading(false))
    }

    // 获取文件夹信息
    const getFolderDetail = (id: string) => {
        post({
            url: 'ShareFolder/GetFolderDetailById',
            data: {folderId: id}
        })
        .then(res => {
            if(res.IsSuccess) {
                const data = res.Data as FolderDetail
                folderAuthDetail.current = data.AuthDetailList
            }
        })
    }

    // 删除文件夹
    const deleteFolder = () => {
        setLoading(true)
        post({
            url: '/ShareFolder/BatchDelDocs',
            data: { 
                folderId: folderInfo?.FolderId,
                Ids: selectRows,
            }
        })
        .then(res => {
            if (res.IsSuccess) {
                const newFolderList = folderList.filter(folder => !selectRows.includes(folder.Id))
                setFolderList(newFolderList)
                setSelectRows([])

                notification.success({
                    message: '提示',
                    description: '删除成功',
                })
            } else {
                notification.error({
                    message: '提示',
                    description: '删除失败',
                })
            }
        })
        .finally(() => setLoading(false))
    }

    // 检查上传文档
    const beforeUploadCheck = (file: RcFile) => {
        const formData = new FormData()
        formData.append("file", file)

        post({
            url: '/ShareFolder/CheckFileName?folderId=' + folderInfo?.FolderId,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
        .then(res => {
            if (res.IsSuccess) {
                if (res.Data.isDuplicate) { // 上传文档重复, 询问是否继续上传
                    Modal.confirm({
                        title: '提示',
                        content: '当前文件夹下已存在同名文档, 是否继续上传?',
                        onOk: () => uploadDocument(formData),
                    })
                } else {
                    uploadDocument(formData)
                }
            } else {
                notification.error({
                    message: '提示',
                    description: '上传文档错误, 校验文件名失败',
                })
            }
        })
    }

    // 上传文档
    const uploadDocument = (formData: FormData) => {
        post({
            url: '/ShareFolder/UploadDoc?folderId=' + folderInfo?.FolderId,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
        .then(res => {
            if (res.IsSuccess) {
                getFolderInfo(folderInfo?.FolderId ?? '')

                notification.success({
                    message: '提示',
                    description: '上传成功',
                })
            } else {
                notification.error({
                    message: '提示',
                    description: '上传失败',
                })
            }
        })
    }

    return (
        <div className='folder-list-panel'>
            <div className='toolbar'>
                <Space size={20}>
                    <Upload
                        beforeUpload={file => {
                            beforeUploadCheck(file)
                            return false
                        }}
                        showUploadList={false}
                        onRemove={removeFile => {
                            const newFileList = uploadFileList.filter(file => file.uid !== removeFile.uid)
                            setUploadFileList(newFileList)
                        }}
                    >
                        <Button 
                            type="primary" 
                            icon={<i className='fm-icon-add-document h-margin-r-5' />}
                            disabled={!folderInfo || !folderInfo.IsCanUploadDoc}
                        >上传文档</Button>
                    </Upload>
                    
                    <Button 
                        type="text" 
                        icon={<i className='fm-icon-add-folder h-margin-r-5' style={{color: '#7d191e'}} />}
                        disabled={!folderInfo || !folderInfo.IsCanCreateFolder}
                        onClick={() => setSaveFolderModalController({visible: true})}
                    >新建文件夹</Button>
                </Space>
                
                <div className='search'>
                    <Input
                        value={keyword}
                        prefix={<i className="fm-icon-search" />}
                        placeholder="搜索" 
                        bordered={false} 
                        allowClear
                        onChange={e => setKeyword(e.target.value)}
                    />
                </div>
            </div>

            <div className='folder-list'>
                <div className='folder-list__header'>
                    <Space split={<Divider type="vertical" />}>
                        <Space>
                            <Button 
                                size='small' 
                                type='text' 
                                disabled={!folderInfo || folderInfo.DocPathList.length < 2}
                                icon={<i className='fm-icon-arrow-left' />} 
                                onClick={() => {
                                    if (folderInfo && folderInfo.DocPathList.length >= 2) {
                                        const folderId = folderInfo.DocPathList[folderInfo.DocPathList.length - 2].FolderId
                                        navigate('/folder?id=' + folderId)
                                    }
                                }}
                            />
                        </Space>
                        
                        <Breadcrumb separator=">">
                            {
                                folderInfo && folderInfo.DocPathList.length >= 1
                                &&
                                <Breadcrumb.Item onClick={() => navigate('/folder?id=' + folderInfo.DocPathList[0].FolderId)}>
                                    <HomeOutlined />
                                </Breadcrumb.Item>
                            }
                            {
                                folderInfo?.DocPathList.map(path => (
                                    <Breadcrumb.Item 
                                        key={path.FolderId} 
                                        onClick={() => navigate('/folder?id=' + path.FolderId)}
                                    >
                                        <span style={{cursor: 'pointer'}}>{path.FolderName}</span>
                                    </Breadcrumb.Item>
                                ))
                            }
                        </Breadcrumb>
                    </Space>
                </div>
                
                <Table
                    rowKey="Id"
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'Name',
                            key: 'Name',
                            render: (value, record) => (
                                <div 
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* 复选框 */}
                                    <Checkbox 
                                        className='h-margin-r-15'
                                        checked={selectRows.includes(record.Id)}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setSelectRows([
                                                    ...selectRows,
                                                    record.Id,
                                                ])
                                            } else {
                                                setSelectRows([
                                                    ...selectRows.filter(id => id !== record.Id)
                                                ])
                                            }
                                        }}
                                    />

                                    {/* 文件夹、文件名 */}
                                    <div
                                        style={{
                                            cursor: 'pointer', 
                                            // flex: '1', 
                                            overflow: 'hidden', 
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        title={value}
                                        onClick={() => {
                                            if (record.FolderType === FolderType.directory) { // 打开文件夹
                                                navigate('/folder?id=' + record.Id)
                                            } else { // 下载文件
                                                downloadFile(record.Name, record.Path, false)
                                            }
                                        }}
                                    >
                                        {
                                            record.FolderType === FolderType.directory
                                            ?
                                            <i className='fm-icon-folder-close' style={{ "color": "#e7bc71" }} />
                                            :
                                            <i className='fm-icon-file' style={{ "color": "#e7bc71" }} />
                                        }
                                        <div className='h-ellipsis-1 h-margin-l-10'>{value}</div>
                                    </div>

                                    {/* 编辑文件夹按钮 */}
                                    <a 
                                        className='h-margin-l-15'
                                        style={{
                                            visibility: activeRow === record.Id && record.IsCanEdit ? 'visible' : 'hidden',
                                            whiteSpace: 'nowrap',
                                        }}
                                        onClick={() => setSaveFolderModalController({visible: true, folderId: record.Id})}
                                    >修改</a>
                                </div>
                            ),
                        },
                        {
                            title: '创建人',
                            dataIndex: 'Creator',
                            key: 'Creator',
                            ellipsis: true,
                            width: 200,
                            align: 'center',
                        },
                        {
                            title: '修改时间',
                            dataIndex: 'ModifyTime',
                            key: 'ModifyTime',
                            width: 300,
                            align: 'center',
                            render: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
                        },
                    ]}
                    dataSource={[...folderList.filter(folder => folder.Name.indexOf(keyword) !== -1)]}
                    onRow={record => {
                        return {
                            // 鼠标移入行
                            onMouseEnter: () => {
                                if (record.FolderType === FolderType.directory) {
                                    setActiveRow(record.Id)
                                }
                            },
                            // 鼠标移出行
                            onMouseLeave: () => setActiveRow(undefined),
                        }
                    }}
                    loading={{
                        spinning: loading,
                        tip: '加载中...'
                    }}
                    pagination={false}
                    footer={() => (
                        <Space split={<Divider type="vertical" style={{borderColor: '#fff'}} />}>
                            <Checkbox 
                                style={{color: '#fff'}}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setSelectRows([
                                            ...folderList.map(folder => folder.Id)
                                        ])
                                    } else {
                                        setSelectRows([])
                                    }
                                }}
                            >全选</Checkbox>

                            <Button 
                                size='small'
                                disabled={!folderInfo || folderInfo.IsCanDelete !== true || selectRows.length <= 0}
                                onClick={deleteFolder}
                            >删除</Button>
                        </Space>
                    )}
                />
            </div>

            <SaveFolderModal 
                visible={saveFolderModalController.visible} 
                parentFolderId={folderInfo?.FolderId}
                parentFolderAuthDetail={folderAuthDetail.current}
                folderId={saveFolderModalController.folderId}
                originatorTreeData={originatorTree}
                onClose={() => setSaveFolderModalController({visible: false})}
                onSaved={() => getFolderInfo(folderInfo?.FolderId ?? '')}
            />
        </div>
    )
}

// 历史删除记录
const DeleteRecordPanel = () => {
    const [loading, setLoading] = useState(false)
    const [folderList, setFolderList] = useState<FolderItem[]>([]) // 删除文件夹列表
    const [selectRows, setSelectRows] = useState<string[]>([]) // 用户勾选的表格行 id
    const [keyword, setKeyword] = useState('') // 搜索关键词

    useEffect(() => {
        getDeleteFolderList()
    }, [])

    // 获取删除的文件夹列表
    const getDeleteFolderList = () => {
        setLoading(true)
        // 获取删除记录
        post({
            url: '/ShareFolder/GetDeleteFolderInfo',
        })
        .then(res => {
            if (res.IsSuccess) {
                setFolderList(res.Data)
            }
        })
        .finally(() => setLoading(false))
    }

    // 检查恢复文档
    const beforeRecoverCheck = () => {
        post({
            url: '/ShareFolder/CheckRecvDocName',
            data: {Ids: selectRows}
        })
        .then(res => {
            if (res.IsSuccess) {
                if (res.Data.isDuplicate) { // 上传文档重复, 询问是否继续上传
                    Modal.confirm({
                        title: '提示',
                        content: '恢复文档的原文件夹下已存在同名文档, 是否继续恢复?',
                        onOk: () => recoverDocs(),
                    })
                } else {
                    recoverDocs()
                }
            } else {
                notification.error({
                    message: '提示',
                    description: '恢复文档错误, 校验文件名失败',
                })
            }
        })
    }

    // 恢复文档
    const recoverDocs = () => {
        setLoading(true)
        post({
            url: '/ShareFolder/RecoverDocs',
            data: {Ids: selectRows}
        })
        .then(res => {
            if(res.IsSuccess) {
                const newFolderList = folderList.filter(folder => !selectRows.includes(folder.Id))
                setFolderList(newFolderList)
                setSelectRows([])
                setKeyword('')

                notification.success({
                    message: '提示',
                    description: '恢复成功',
                })
            } else {
                notification.error({
                    message: '提示',
                    description: '恢复失败',
                })
            }
        })
        .finally(() => setLoading(false))
    }

    return (
        <div className='delete-record-panel'>
            <div className='toolbar'>
                <h3 className='title'>历史删除记录</h3>

                <div className='search'>
                    <Input
                        value={keyword}
                        prefix={<i className="fm-icon-search" />}
                        placeholder="搜索" 
                        bordered={false} 
                        allowClear
                        onChange={e => setKeyword(e.target.value)}
                    />
                </div>
            </div>

            <div className='folder-list'>
                <Table
                    rowKey="Id"
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'Name',
                            key: 'Name',
                            ellipsis: true,
                            render: (value, record) => (
                                <div 
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* 复选框 */}
                                    <Checkbox 
                                        className='h-margin-r-15'
                                        checked={selectRows.includes(record.Id)}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setSelectRows([
                                                    ...selectRows,
                                                    record.Id,
                                                ])
                                            } else {
                                                setSelectRows([
                                                    ...selectRows.filter(id => id !== record.Id)
                                                ])
                                            }
                                        }}
                                    />

                                    <div className='h-ellipsis-1' title={value}>{value}</div>
                                </div>
                            ),
                        },
                        {
                            title: '路径',
                            dataIndex: 'Path',
                            key: 'Path',
                            ellipsis: true,
                        },
                        {
                            title: '类型',
                            dataIndex: 'FolderTypeDesc',
                            key: 'FolderTypeDesc',
                            width: 80,
                            align: 'center',
                            ellipsis: true,
                        },
                        {
                            title: '删除人',
                            dataIndex: 'Deletion',
                            key: 'Deletion',
                            width: 150,
                            align: 'center',
                            ellipsis: true,
                        },
                        {
                            title: '删除时间',
                            dataIndex: 'DeleteTime',
                            key: 'DeleteTime',
                            width: 200,
                            align: 'center',
                            render: value => moment(value).format('YYYY-MM-DD HH:mm:ss')
                        },
                    ]}
                    dataSource={[...folderList.filter(folder => folder.Name.indexOf(keyword) !== -1)]}
                    loading={{
                        spinning: loading,
                        tip: '加载中...'
                    }}
                    pagination={{
                        position: ['bottomCenter'],
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: total => `共 ${total} 条`,
                        onChange: () => setSelectRows([]),
                    }}
                    footer={() => (
                        <Space split={<Divider type="vertical" style={{borderColor: '#fff'}} />}>
                            <Checkbox 
                                style={{color: '#fff'}}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setSelectRows([
                                            ...folderList
                                                .filter(folder => folder.Name.indexOf(keyword) !== -1)
                                                .map(folder => folder.Id)
                                        ])
                                    } else {
                                        setSelectRows([])
                                    }
                                }}
                            >全选</Checkbox>

                            <Button 
                                size='small'
                                disabled={selectRows.length <= 0}
                                onClick={beforeRecoverCheck}
                            >恢复</Button>
                        </Space>
                    )}
                />
            </div>
        </div>
    )
}

// 保存文件夹对话框
const SaveFolderModal = ({visible, parentFolderId, parentFolderAuthDetail, folderId, originatorTreeData, onClose, onSaved}: {
    visible: boolean
    parentFolderId?: string // 上级文件夹 id
    parentFolderAuthDetail: AuthDetailItem[] // 上级文件夹权限
    folderId?: string // 被编辑文件夹的 id
    originatorTreeData: OriginatorItem[]
    onClose: () => void
    onSaved: () => void
}) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const folderAuthDetail = useRef<AuthDetailItem[]>([]) // 当前文件夹权限列表
    // 已选择的指定人和分配权限保存信息
    const [selectedAssignInfo, setSelectedAssignInfo] = useState<{
        // 选择的用户列表
        users: {userid: string, username: string}[]
        // 给指定用户分配的权限
        auths: string[]
    }>({users: [], auths: []})
    // 指定权限: 用于生成 [权限树形控件数据] 和 [指定人权限列表]
    const assignAuth = useRef<AssignAuthItem[]>([])
    // 权限树形控件
    const [authTree, setAuthTree] = useState<DataNode[]>([])
    const [validateAssignAuthTip, setValidateAssignAuthTip] = useState<{validateStatus: 'warning' | 'error' | 'success', help: string}>()
    // 文件夹名称输入框
    const inputRef = useRef<InputRef>(null)

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (folderId) { // 编辑文件夹
            getFolderDetail()
        } else { // 创建文件夹
            form.setFieldsValue({folderId: parentFolderId})
            parseAuthDetailList(parentFolderAuthDetail)
        }
    }, [visible])

    // 获取文件夹信息
    const getFolderDetail = () => {
        setLoading(true)
        post({
            url: 'ShareFolder/GetFolderDetailById',
            data: {folderId: folderId}
        })
        .then(res => {
            if(res.IsSuccess) {
                const data = res.Data as FolderDetail
                form.setFieldsValue({...data})
                folderAuthDetail.current = data.AuthDetailList
                parseAuthDetailList(data.AuthDetailList)
            }
        })
        .finally(() => setLoading(false))
    }

    // 验证用户已选的指定信息
    const validateSelectedAssignInfo = (): boolean => {
        if (selectedAssignInfo.users.length <= 0) {
            // 未指定用户
            setValidateAssignAuthTip({validateStatus: 'warning', help: '未指定用户'})
            return false
        }
        if (selectedAssignInfo.auths?.length <= 0) {
            // 未分配权限
            setValidateAssignAuthTip({validateStatus: 'warning', help: '未指定权限'})
            return false
        }

        setValidateAssignAuthTip(undefined)
        return true
    }

    // 刷新树形控件数据
    const refreshAuthTreeData = () => {
        const treeNode: DataNode[] = []

        for (let i = 0; i < assignAuth.current.length; i++) {
            const auth = assignAuth.current[i];

            treeNode.push({
                title: auth.text,
                key: auth.flag,
                icon: <i className='fm-icon-privilege' style={{color: '#2f54eb'}} />,
                children: [
                    ...auth.users.map(user => ({
                        title: (
                            <Space size={30}>
                                <span>{user.username}</span>
                                {
                                    form.getFieldValue('FolderAuthState') === 2 &&
                                    <Button 
                                        type='link'
                                        icon={<i className='fm-icon-close' />} 
                                        size='small'
                                        onClick={() => removeAssignAuthUser(auth.flag, user.userid)}
                                    />
                                }
                            </Space>
                        ),
                        key: `:${auth.flag}:${user.userid}`,
                        icon: <i className='fm-icon-user-o' style={{color: '#1890ff'}} />,
                    }))
                ],
            })
        }

        setAuthTree(treeNode)
    }

    // 移除某权限下的用户
    const removeAssignAuthUser = (flag: string, userid: string) => {
        const newAssignAuth = [
            ...assignAuth.current.map(auth => {
                if (auth.flag === flag) {
                    auth.users = [...auth.users.filter(user => user.userid !== userid)]
                }
                return auth
            })
        ]
        assignAuth.current = [...newAssignAuth]
        refreshAuthTreeData()
    }

    // 将指定用户添加到权限中
    const addAssignAuthUser = () => {
        if (validateSelectedAssignInfo()) {
            const newAssignAuth = [...assignAuth.current]

            for (let i = 0; i < newAssignAuth.length; i++) {
                const auth = newAssignAuth[i];
                
                if (selectedAssignInfo.auths.includes(auth.flag)) {
                    for (let j = 0; j < selectedAssignInfo.users.length; j++) {
                        const assignUser = selectedAssignInfo.users[j];
                        
                        if (!auth.users.find(user => user.userid === assignUser.userid )) {
                            auth.users.push({...assignUser})
                        }
                    }
                }
            }

            // 清空已选的用户及权限的保存信息
            setSelectedAssignInfo({
                users: [],
                auths: [],
            })

            assignAuth.current = [...newAssignAuth]
            refreshAuthTreeData()
        }

        
        /**
         * 由于使用 open={false} 来控制 TreeSelect 关闭是无效的, 具体原因未知
         * 所以这里让 Input 输入框被选中, 让 TreeSelect 失去焦点来实现关闭效果
         */
        inputRef.current?.select()
    }

    // 检查文件夹名称
    const beforeCreateFolderCheck = (values: any) => {
        const {FolderName, FolderAuthState} = values 

        return new Promise((resolve, reject) => {
            post({
                url: 'ShareFolder/CheckFolderName',
                data: {
                    FolderName,
                    FolderAuthState,
                    folderId: parentFolderId,
                }
            })
            .then(res => {
                if (res.IsSuccess) {
                    if (res.Data.isDuplicate) {
                        Modal.confirm({
                            title: '提示',
                            content: '当前文件夹下已存在同名文件夹, 是否继续创建?',
                            onOk: () => resolve('继续创建同名文件夹'),
                            onCancel: () => reject('取消创建文件夹'),
                        })
                    } else {
                        resolve('文件夹名称检查通过');
                    }
                } else {
                    reject('文件夹名称检查失败');
                    notification.error({
                        message: '提示',
                        description: '创建文件夹失败',
                    })
                }
            })
            .catch(() => {
                reject('文件夹名称检查失败')
                notification.error({
                    message: '提示',
                    description: '创建文件夹失败',
                })
            })
        })
    }

    // 提交
    const onSubmit = async (values: any) => {
        const {FolderName, FolderAuthState} = values 
        const AuthDetailList: AuthDetailItem[] = buildAuthDetailList()

        if (FolderAuthState === 2 && AuthDetailList.length <= 0) {
            setValidateAssignAuthTip({
                validateStatus: 'error',
                help: '未指定用户权限',
            })
            return 
        }

        setLoading(true)

        if (!folderId) { // 新建文件夹
            try {
                await beforeCreateFolderCheck(values)
            } catch (error) {
                setLoading(false)
                return
            }
        }

        post({
            url: folderId ? 'ShareFolder/UpdateFolder' : 'ShareFolder/CreateFolder',
            data: {
                FolderName,
                FolderAuthState,
                folderId: folderId ? folderId : parentFolderId,
                AuthDetailList: FolderAuthState === 2 ? AuthDetailList : undefined
            }
        })
        .then(res => {
            if(res.IsSuccess) {
                onClose()
                onSaved()
                notification.success({
                    message: '提示',
                    description: folderId ? '编辑文件夹成功' : '创建文件夹成功',
                })
            } else {
                notification.error({
                    message: '提示',
                    description: folderId ? '编辑文件夹失败' : '创建文件夹失败',
                })
            }
        })
        .finally(() => setLoading(false))
    }

    // 解析指定人权限列表
    const parseAuthDetailList = (authDetailList: AuthDetailItem[]) => {
        const [auth_u, auth_d, auth_c, auth_e] = [
            {
                text: '文档上传',
                flag: 'u',
                users: [],
            },
            {
                text: '文档删除',
                flag: 'd',
                users: [],
            },
            {
                text: '新建文件夹',
                flag: 'c',
                users: [],
            },
            {
                text: '修改文件夹',
                flag: 'e',
                users: [],
            },
        ] as AssignAuthItem[]

        for (let i = 0; i < authDetailList.length; i++) {
            const authDetail = authDetailList[i];
            if (authDetail.authType_u) {
                for (let j = 0; j < authDetail.user.length; j++) {
                    const user = authDetail.user[j];
                    if (!auth_u.users.find(item => item.userid === user.userid)) {
                        auth_u.users.push(user)
                    }
                }
            }
            if (authDetail.authType_d) {
                for (let j = 0; j < authDetail.user.length; j++) {
                    const user = authDetail.user[j];
                    if (!auth_d.users.find(item => item.userid === user.userid)) {
                        auth_d.users.push(user)
                    }
                }
            }
            if (authDetail.authType_c) {
                for (let j = 0; j < authDetail.user.length; j++) {
                    const user = authDetail.user[j];
                    if (!auth_c.users.find(item => item.userid === user.userid)) {
                        auth_c.users.push(user)
                    }
                }
            }
            if (authDetail.authType_e) {
                for (let j = 0; j < authDetail.user.length; j++) {
                    const user = authDetail.user[j];
                    if (!auth_e.users.find(item => item.userid === user.userid)) {
                        auth_e.users.push(user)
                    }
                }
            }
        }

        assignAuth.current = [
            auth_u, auth_d, auth_c, auth_e
        ]
        refreshAuthTreeData()
    }

    // 构建指定人权限列表
    const buildAuthDetailList = (): AuthDetailItem[] =>  {
        const [auth_u, auth_d, auth_c, auth_e] = [...assignAuth.current]
        // 取4个权限下的用户 (去重)
        const users = _unionBy([...auth_u.users, ...auth_d.users, ...auth_c.users, ...auth_e.users], 'userid')
        // 权限标识表
        const flagMap: {[index: string]: {userid: string, username: string}[]} = {}

        for (let i = 0; i < users.length; i++) {
            const hasU = auth_u.users.find(user => user.userid === users[i].userid) ? 1 : 0
            const hasD = auth_d.users.find(user => user.userid === users[i].userid) ? 1 : 0
            const hasC = auth_c.users.find(user => user.userid === users[i].userid) ? 1 : 0
            const hasE = auth_e.users.find(user => user.userid === users[i].userid) ? 1 : 0
            const flag = `${hasU},${hasD},${hasC},${hasE}` // 权限标识以逗号分隔 u,d,c,e 每个位置中 1: 表示有权限 0: 表示没有权限

            if (flagMap[flag]) { // 权限标识已存在
                if (!flagMap[flag].find(user => user.userid === users[i].userid)) { // 用户已添加在标识表中
                    flagMap[flag].push(users[i])
                }
            } else {
                flagMap[flag] = [users[i]]
            }
        }

        // 根据权限标识表, 创建指定人权限列表
        const data: AuthDetailItem[] = []
        
        for(let flag in flagMap) {
            const [u, d, c, e] = flag.split(",")
            data.push({
                user: flagMap[flag],
                authType_u: u === '1',
                authType_d: d === '1',
                authType_c: c === '1',
                authType_e: e === '1',
            })
        }

        return data
    }

    return (
        <Modal 
            visible={visible}
            title={folderId ? '编辑文件夹' : '新建文件夹'} 
            width={700}
            maskClosable
            centered
            forceRender
            cancelText='关闭'
            confirmLoading={loading}
            onCancel={onClose}
            onOk={() => form.submit()}
            afterClose={() => {
                // 重置对话框
                form.resetFields()
                setValidateAssignAuthTip(undefined)
                setSelectedAssignInfo({
                    users: [],
                    auths: [],
                })
                assignAuth.current = [
                    {
                        text: '文档上传',
                        flag: 'u',
                        users: [],
                    },
                    {
                        text: '文档删除',
                        flag: 'd',
                        users: [],
                    },
                    {
                        text: '新建文件夹',
                        flag: 'c',
                        users: [],
                    },
                    {
                        text: '修改文件夹',
                        flag: 'e',
                        users: [],
                    },
                ]
                folderAuthDetail.current = []
                refreshAuthTreeData()
            }}
        >
            <Form
                form={form}
                requiredMark={false}
                labelCol={{flex: '80px'}}
                initialValues={{
                    FolderAuthState: 4,
                }}
                onFinish={onSubmit}
            >
                <Form.Item hidden name='folderId'><Input /></Form.Item>

                <Form.Item
                    label="文件夹名称"
                    name="FolderName"
                    rules={[{ required: true, message: '文件夹名称不能为空' }]}
                >
                    <Input ref={inputRef} readOnly={folderId !== undefined} />
                </Form.Item>
                
                <Form.Item 
                    label="访问权限"
                    name="FolderAuthState"
                    rules={[{ required: true, message: '请选择访问权限' }]}
                >
                    <Radio.Group 
                        onChange={e => {
                            if (e.target.value === 2) { // 指定人员
                                parseAuthDetailList(folderAuthDetail.current)
                            } else { // 默认
                                parseAuthDetailList(parentFolderAuthDetail)
                            }
                        }}
                    >
                        <Radio value={4}>默认</Radio>
                        <Radio value={2}>指定人员</Radio>
                    </Radio.Group>
                </Form.Item>
                
                <Row>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.FolderAuthState !== curValues.FolderAuthState}>
                        {
                            ({getFieldValue}) => {
                                if (getFieldValue('FolderAuthState') === 2) {
                                    return (
                                        <Col span={24}>
                                        <Form.Item 
                                            label="设置权限" 
                                            validateStatus={validateAssignAuthTip?.validateStatus ?? ''}
                                            help={validateAssignAuthTip?.help}
                                        >
                                                <TreeSelect
                                                    value={selectedAssignInfo.users.map(user => user.userid)}
                                                    placeholder="指定人"
                                                    style={{ width: '100%' }}
                                                    fieldNames={{ label: 'text', value: 'id', children: 'children' }}
                                                    treeData={originatorTreeData}
                                                    multiple
                                                    treeCheckable
                                                    showSearch={false}
                                                    showArrow
                                                    maxTagCount='responsive'
                                                    allowClear
                                                    listHeight={200}
                                                    dropdownRender={menu => (
                                                        <>
                                                            {menu}
                                                            <Divider style={{ margin: '8px 0' }} />
                                                            <div 
                                                                style={{
                                                                    display: 'flex', 
                                                                    justifyContent: 'space-between', 
                                                                    alignItems: 'center',
                                                                    padding: '0 25px',
                                                                }}>
                                                                <label 
                                                                    className='h-margin-r-15'
                                                                    style={{color: '#f5222d', fontSize: '12px'}}
                                                                >权限分配: </label>
                                                                <Checkbox.Group 
                                                                    style={{flex: '1'}}
                                                                    value={selectedAssignInfo.auths}
                                                                    options={[
                                                                        { label: '文档上传', value: 'u' },
                                                                        { label: '文档删除', value: 'd' },
                                                                        { label: '新建文件夹', value: 'c' },
                                                                        { label: '修改文件夹', value: 'e' },
                                                                    ]} 
                                                                    onChange={values => {
                                                                        setSelectedAssignInfo({
                                                                            users: selectedAssignInfo.users,
                                                                            auths: values as string[],
                                                                        })
                                                                    }}
                                                                />
                                                                <Button 
                                                                    className='h-margin-l-15'
                                                                    type='primary'
                                                                    onClick={addAssignAuthUser}
                                                                >添加</Button>
                                                            </div>
                                                        </>
                                                    )}
                                                    onChange={(values, labels) => {
                                                        const userIds = values as string[]
                                                        const userNames = labels as string[]
                                                        if (userIds.length === userNames.length) {
                                                            const users = []
                                                            for (let i = 0; i < userIds.length; i++) {
                                                                users.push({
                                                                    userid: userIds[i],
                                                                    username: userNames[i]
                                                                })
                                                            }
                                                            setSelectedAssignInfo({
                                                                users,
                                                                auths: selectedAssignInfo.auths
                                                            })
                                                        }
                                                    }}
                                                />
                                            
                                        </Form.Item>
                                        </Col>
                                    )
                                }
                            }
                        }
                    </Form.Item>

                    <Col span={24} style={{paddingLeft: '80px'}}>
                        <Tree
                            style={{width: '100%', height: '260px'}}
                            height={260}
                            showIcon
                            defaultExpandAll 
                            treeData={authTree}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}