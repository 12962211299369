import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Image, Empty } from 'antd';
import { post } from "../../../../utils/axios"
import FallbackImg from "./../../../../assets/images/fallback-img_1.jpg";

type NoticeItem = {
    ObjectID: string
    Url: string
    titel: string
    pimg: string
    showType: string
}

export default ({ itemClick }: { itemClick: (type: string, url: string) => void }) => {
    const [activeNoticeItem, setActiveNoticeItem] = useState<NoticeItem>() // 鼠标选中项
    const [noticeList, setNoticeList] = useState<NoticeItem[]>([])

    useEffect(() => {
        getNoticeList()
    }, [])

    // 获取新闻列表
    const getNoticeList = () => {
        post({
            url: 'BpmQuery/QueryNoticeList',
            data: { NoticeType: '新闻动态', PageSize: 7 }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data.List as NoticeItem[]
                    
                    // 添加延迟, 以防bpm未登录导致图片加载失败
                    setTimeout(() => {
                        if (data.length > 0) {
                            setActiveNoticeItem(data[0])
                        }
                    }, 1000)

                    setNoticeList(data)
                }
            })
    }

    return (
        <section className="section-container section_2-1">
            <div className="section-header">
                <h2 className="title">新闻动态</h2>

                <Link to={'/notice'} state={{ noticeType: '新闻动态', displayThumb: true }} className="more">更多&gt;&gt;</Link>
            </div>

            <div className="section-body">
                <div className='img' style={{ 'display': noticeList.length > 0 ? 'block' : 'none' }}>
                    <Image
                        width='100%'
                        preview={false}
                        src={activeNoticeItem?.pimg || 'err'}
                        fallback={FallbackImg}
                        onClick={() => itemClick(activeNoticeItem!.showType, activeNoticeItem!.Url)}
                    />
                </div>

                <ul className='list' style={{ 'display': noticeList.length > 0 ? 'block' : 'none' }}>
                    {
                        noticeList.map(item => (
                            <li className='item'
                                key={item.ObjectID}
                                onMouseOver={() => setActiveNoticeItem(item)}
                                onClick={() => itemClick(item.showType, item.Url)}
                            >
                                <div className='title h-ellipsis-1'>{item.titel}</div>
                            </li>
                        ))
                    }
                </ul>

                <Empty
                    style={{ 'display': noticeList.length > 0 ? 'none' : 'block', 'flex': 1 }}
                    description="暂无相关公告"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        </section>
    )
}