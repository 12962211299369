import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Dropdown, Menu, Modal, Form, Input, Button, Space, notification } from 'antd';
import type { MenuProps } from 'antd';
import { useAppSelector } from '../../../store/hook'
import PubSub from 'pubsub-js'
import PlaceholderAvatar from "../../../assets/images/placeholder-avatar.png";
import { post } from "../../../utils/axios"

export default () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [workItemCount, setWorkItemCount] = useState(0);
    const [circulateItemCount, setCirculateItemCount] = useState(0);
    const [visibleFolder, setVisibleFolder] = useState(false); // 是否拥有查看共享文件夹权限

    useEffect(() => {
        getHeaderData()
        
        // 共享文件夹权限
        post({
            url: 'ShareFolder/IsHaveAuth',
        })
        .then(res => {
            if (res.IsSuccess) {
                setVisibleFolder(res.Data.IsHaveAuth)
            }
        })

        /**
         * 消息订阅
         * 
         * - 浏览器标签页切换
         */
        const token = PubSub.subscribe('BrowserTabsToggleEvent', getHeaderData)
        return () => {
            // 退订所有消息
            PubSub.unsubscribe(token)
        }
    }, []);

    // 获取头部待办、待阅数量
    const getHeaderData = () => {
        Promise.all([
            // 获取待办信息列表
            post({
                url: 'BpmQuery/QueryWorkItemList',
                data: { PageSize: 1 }
            }, true),
            // 获取待阅信息列表
            post({
                url: 'BpmQuery/QueryCirculateItemList',
                data: { PageSize: 1 }
            }, true)
        ]).then(([workItemList, circulateItemList]) => {
            if (workItemList.IsSuccess) {
                setWorkItemCount(workItemList.Data.Datacount)
            }
            if (circulateItemList.IsSuccess) {
                setCirculateItemCount(circulateItemList.Data.Datacount)
            }
        }
        );
    }

    return (
        <div className="page-header">
            <div className="menubar">
                <ul className="menubar-list">
                    <li className="menubar-list__item dropdown-menu__system">
                        <SystemDropdownMenu />
                    </li>

                    <li className="menubar-list__item bespread"></li>

                    <li className="menubar-list__item">
                        <Badge
                            count={workItemCount}
                            offset={[10, -3]}
                            style={{ "backgroundColor": '#7d191e' }}
                        >
                            <Link to={"/workbench"}>待办</Link>
                        </Badge>
                    </li>

                    <li className="menubar-list__item">
                        <Badge
                            count={circulateItemCount}
                            offset={[10, -3]}
                            style={{ "backgroundColor": '#7d191e' }}
                        >
                            <Link to={"/workbench/MyUnReadWorkItem"}>待阅</Link>
                        </Badge>
                    </li>

                    <li className="menubar-list__item dropdown-menu__user">
                        <UserDropdownMenu />
                    </li>
                </ul>
            </div>

            <div className="navbar">
                <ul className="navbar-list">
                    <li
                        className={pathname === '/' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/')}
                    >首页</li>
                    <li
                        className={pathname.indexOf('/workbench') !== -1 ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/workbench')}
                    >工作台</li>
                    <li
                        className={pathname === '/norm' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/norm')}
                    >制度规范</li>
                    <li
                        className={pathname === '/report' ? "navbar-list__item active" : "navbar-list__item"}
                        onClick={() => navigate('/report')}
                    >报表中心</li>
                    {
                        visibleFolder &&
                        <li
                            className={pathname === '/folder' ? "navbar-list__item active" : "navbar-list__item"}
                            onClick={() => navigate('/folder')}
                        >共享文件夹</li>
                    }
                </ul>
            </div>
        </div>
    )
}

// 用户下拉菜单
const UserDropdownMenu = () => {
    const userInfo = useAppSelector(state => state.currentUser.userInfo)
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false); // 是否显示重置密码对话框
    const [loading, setLoading] = useState(false)

    // 点击下拉菜单项 
    const handleMenuItemClick = (key: string) => {
        switch (key) {
            case 'Logout':
                // 发送用户退出事件
                PubSub.publish('UserLogoutEvent')
                break;
            case 'ResetPassword':
                // 重置密码
                setIsModalVisible(true)
                break;
            case 'CommonOpinion':
                // 常用意见
                window.open('http://oa.zgproperty.cn/Portal/index.html#/app/Workflow/MyComments', '_blank');
                break;
        }
    }

    // 重置密码
    const handelResetPassword = (values: any) => {
        const { oldPassword, newPassword } = values

        setLoading(true)
        post({
            url: 'Employee/ModifyPsw',
            params: {
                OldPsw: oldPassword,
                NewPlsw: newPassword,
            }
        })
            .then(res => {
                if (res.IsSuccess) {
                    notification.success({
                        message: '提示',
                        description: '密码修改成功'
                    })
                    // 关闭对话框
                    setIsModalVisible(false)
                } else {
                    notification.error({
                        message: '提示',
                        description: res.Msg
                    })
                    // 清空旧密码
                    form.resetFields(['oldPassword'])
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Dropdown
                placement="bottom"
                arrow
                overlay={
                    <Menu
                        style={{ "width": "170px" }}
                        onClick={(info) => handleMenuItemClick(info.key)}
                        items={[
                            {
                                key: 'ResetPassword',
                                label: '重设密码',
                            },
                            {
                                key: 'CommonOpinion',
                                label: '常用意见',
                            },
                            {
                                type: "divider"
                            },
                            {
                                key: 'Logout',
                                label: '退出',
                            }
                        ]}
                    />
                }
            >
                <span style={{ "cursor": "pointer" }}>
                    <img src={PlaceholderAvatar} />&nbsp;&nbsp;{userInfo?.EmployeeName}
                </span>
            </Dropdown>

            {/* 重设密码对话框 */}
            <Modal
                title="重设密码"
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form
                    form={form}
                    onFinish={handelResetPassword}
                    requiredMark={false}
                >
                    <Form.Item
                        label="旧密码"
                        name="oldPassword"
                        rules={[{ required: true, message: '密码不能为空' }]}
                    >
                        <Input.Password placeholder='请输入旧密码' autoComplete='off' />
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[{ required: true, message: '密码不能为空' }]}
                    >
                        <Input.Password placeholder='请输入新密码' autoComplete='off' />
                    </Form.Item>

                    <Form.Item style={{ "textAlign": "right" }} >
                        <Button
                            htmlType="reset"
                            onClick={() => setIsModalVisible(false)}
                        >取消</Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ "marginLeft": "20px" }}
                            loading={loading}
                        >修改密码</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

// 系统下拉菜单
const SystemDropdownMenu = () => {
    // 下拉菜单项
    const [menuItems, setMenuItems] = useState<MenuProps['items']>([])

    useEffect(() => {
        getSingleSignWebList()
    }, [])

    // 获取单点登录网站列表
    const getSingleSignWebList = () => {
        const basicMenu = [
            {
                key: 'system1',
                label: 'EHR系统',
            },
            {
                key: 'system2',
                label: '一点停车场系统',
            },
            {
                key: 'system3',
                label: 'BI系统',
            },
            {
                key: 'system4',
                label: '招聘系统',
            },
            {
                key: 'BPM',
                label: 'BPM',
            },
        ]

        post({ url: 'Login/GetSingleSignWebList' })
            .then((res) => {
                if (res.IsSuccess) {
                    setMenuItems([
                        ...basicMenu,
                        ...res.Data.map((item: any) => ({
                            key: item.SingleSignWebEntityId,
                            label: item.WebName,
                        })),
                    ])
                }
            });
    }

    // 点击下拉菜单项 
    const handleMenuItemClick = (key: string) => {
        switch (key) {
            case 'system1':
                window.open('http://ehr.zgproperty.cn/templates/index/hcmlogon.jsp;jsessionid=A7D0A4242D28E067C0D0C37FA6EB09CD', '_blank');
                break;
            case 'system2':
                window.open('http://trader.yidianting.xin/', '_blank');
                break;
            case 'system3':
                window.open('http://bi.zgproperty.cn:8088/bi', '_blank');
                break;
            case 'system4':
                window.open('', '_blank');
                break;
            case 'BPM':
                window.open(process.env.REACT_APP_BPM_URL, '_blank');
                break;
            default: // 单点登录
                post({
                    url: 'Login/GetSingleSignUrl',
                    data: { webid: key }
                })
                    .then(res => {
                        if (res.IsSuccess) {
                            // 跳转网站地址
                            window.open(res.Msg, '_blank');
                        }
                    })
                break;
        }
    }

    return (
        <Dropdown
            placement="bottom"
            arrow
            overlay={
                <Menu
                    style={{ "width": "170px" }}
                    onClick={(info) => handleMenuItemClick(info.key)}
                    items={menuItems}
                />
            }
        >
            <a style={{ "color": "#7d191e", "fontWeight": "bold" }} onClick={e => e.preventDefault()}>
                <Space>
                    系统导航 <i className='fm-icon-arrow-down'></i>
                </Space>
            </a>
        </Dropdown>
    )
}