import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Empty } from 'antd';
import { post } from "../../../../utils/axios"
import _slice from 'lodash/slice';

type DocumentItem = {
    Id: string
    Name: string
}

export default () => {
    const navigate = useNavigate();
    const [documentList, setDocumentList] = useState<DocumentItem[]>([])

    useEffect(() => {
        getDocumentList()
    }, [])

    // 获取文档列表
    const getDocumentList = () => {
        post({
            url: 'Document/GetWorkInstructionsDocuments',
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data as DocumentItem[]
                    setDocumentList(_slice(data, 0, 12))
                }
            })
    }

    return (
        <section className='section-container section_3-2'>
            <div className="section-header">
                <h2 className="title">工作指引</h2>

                <Link to={'/guide'} className="more">更多&gt;&gt;</Link>
            </div>

            <div className="section-body">
                <ul className='list' style={{ 'display': documentList.length > 0 ? 'flex' : 'none' }}>
                    {
                        documentList.map(item => (
                            <li
                                className='item'
                                key={item.Id}
                                onClick={() => navigate('/guide', { state: { id: item.Id } })}
                            >
                                <div className='title h-ellipsis-1'>{item.Name}</div>
                            </li>
                        ))
                    }
                </ul>

                <Empty
                    style={{ 'display': documentList.length > 0 ? 'none' : 'block', 'flex': 1 }}
                    description="暂无数据"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        </section>
    )
}