import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { post } from "../../../../utils/axios"

type NoticeItem = {
    ObjectID: string
    Url: string
    titel: string
    showType: string
}

export default ({ itemClick }: { itemClick: (type: string, url: string) => void }) => {
    const [noticeList, setNoticeList] = useState<NoticeItem[]>([])

    useEffect(() => {
        getNoticeList()
    }, [])

    // 获取党群建设列表
    const getNoticeList = () => {
        post({
            url: 'BpmQuery/QueryNoticeList',
            data: { NoticeType: '党群建设', PageSize: 7 }
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data.List as NoticeItem[]
                    setNoticeList(data)
                }
            })
    }

    return (
        <section className='section-container section_2-2'>
            <div className="section-header">
                <h2 className="title">党群建设</h2>

                <Link to={'/notice'} state={{ noticeType: '党群建设' }} className="more">更多&gt;&gt;</Link>
            </div>

            <div className="section-body">
                <ul className='list' style={{ 'display': noticeList.length > 0 ? 'block' : 'none' }}>
                    {
                        noticeList.map(item => (
                            <li className='item' key={item.ObjectID} onClick={() => itemClick(item.showType, item.Url)}>
                                <div className='title h-ellipsis-1'>{item.titel}</div>
                            </li>
                        ))
                    }
                </ul>

                <Empty
                    style={{ 'display': noticeList.length > 0 ? 'none' : 'block' }}
                    description="暂无相关公告"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        </section>
    )
}