import { Routes, Route, Navigate } from "react-router-dom";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import "./index.less";

// 首页
import HomePage from './Home';
// 工作台
import WorkbenchPage from './Workbench';
// 报表中心
import ReportPage from './Report';
// 公告
import NoticePage from './Notice';
// 制度规范
import NormPage from './Norm';
// 工作指引
import GuidePage from './Guide';
// 共享文件夹
import FolderPage from './Folder';

export default () => {
    return (
        <div className="wrap">
            <PageHeader />

            <div className="container">
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="workbench/*" element={<WorkbenchPage />} />
                    <Route path="report" element={<ReportPage />} />
                    <Route path="notice" element={<NoticePage />} />
                    <Route path="norm" element={<NormPage />} />
                    <Route path="guide" element={<GuidePage />} />
                    <Route path="folder" element={<FolderPage />} />
                    <Route path="*" element={<Navigate to='/' />} />
                </Routes>
            </div>

            <PageFooter />
        </div>
    );
};