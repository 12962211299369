import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Empty } from 'antd';
import { post } from "../../../../utils/axios"
import { downloadFile } from "../../../../utils"
import _slice from 'lodash/slice';

type DocumentItem = {
    Level2: string
    Level1: string
    FileName: string
    Url: string
    DownLoadFileName: string
}

export default () => {
    const [documentList, setDocumentList] = useState<DocumentItem[]>([])

    useEffect(() => {
        getDocumentList()
    }, [])

    // 获取文档列表
    const getDocumentList = () => {
        post({
            url: 'Document/GetDocuments',
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data = res.Data as DocumentItem[]
                    setDocumentList(_slice(data, 0, 6))
                }
            })
    }

    return (
        <section className='section-container section_3-1'>
            <div className="section-header">
                <h2 className="title">制度规范</h2>

                <Link to={'/norm'} className="more">更多&gt;&gt;</Link>
            </div>

            <div className="section-body">
                <ul className='list' style={{ 'display': documentList.length > 0 ? 'block' : 'none' }}>
                    {
                        documentList.map((item, index) => (
                            <li
                                className='item'
                                key={item.FileName + index}
                                onClick={() => downloadFile(item.DownLoadFileName, item.Url)}
                            >
                                <div className='title h-ellipsis-1'>{item.FileName}</div>
                                <span className='menu'>{item.Level1}</span>
                            </li>
                        ))
                    }
                </ul>

                <Empty
                    style={{ 'display': documentList.length > 0 ? 'none' : 'block', 'flex': 1 }}
                    description="暂无数据"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            </div>
        </section>
    )
}