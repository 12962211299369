import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { Spin } from 'antd';
import "./index.less"
import { post } from "../../../../utils/axios"

const WorkBoardPage = React.lazy(() => import('./WorkBoard')) // 工作看板
const MyWorkflowPage = React.lazy(() => import('./MyWorkflow')) // 发起流程
const MyUnfinishedWorkItemPage = React.lazy(() => import('./MyUnfinishedWorkItem')) // 待办任务
const MyFinishedWorkItemPage = React.lazy(() => import('./MyFinishedWorkItem')) // 已办任务
const MyUnReadWorkItemPage = React.lazy(() => import('./MyUnReadWorkItem')) // 待阅任务
const MyReadWorkItemPage = React.lazy(() => import('./MyReadWorkItem')) // 已阅任务
const MyInstancePage = React.lazy(() => import('./MyInstance')) // 我的流程
const MyContractPage = React.lazy(() => import('./MyContract')) // 我的合同

export type WorkflowItem = {
    id: string
    text: string
    type: 'mulu' | 'workflow'
    IconUrl?: string
    children?: WorkflowItem[]
}

const createWorkflowTree = (item: WorkflowItem): WorkflowItem => {
    if (item.type === 'mulu') {
        return {
            id: item.id,
            text: item.text,
            type: 'mulu',
            children: item.children?.map(node => createWorkflowTree(node)),
        }
    } else {
        return {
            id: item.id,
            text: item.text,
            type: 'workflow',
            IconUrl: item.IconUrl
        }
    }
}

export type OriginatorItem = {
    id: string
    text: string
    type: 'org' | 'user'
    selectable: Boolean
    children?: OriginatorItem[]
}

const createOriginatorTree = (item: OriginatorItem): OriginatorItem => {
    if (item.type === 'org') {
        return {
            id: item.id,
            text: item.text,
            type: 'org',
            selectable: false,
            children: item.children?.map(node => createOriginatorTree(node)),
        }
    } else {
        return {
            id: item.id,
            text: item.text,
            type: 'user',
            selectable: true,
        }
    }
}

export default () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [workflowTree, setWorkflowTree] = useState<WorkflowItem[]>([])
    const [originatorTree, setOriginatorTree] = useState<OriginatorItem[]>([])

    useEffect(() => {
        getWorkflowList()
        getOriginatorTree()
    }, [])

    // 获取流程模型信息列表
    const getWorkflowList = () => {
        post({
            url: 'BpmQuery/QueryWorkFlowList',
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data: WorkflowItem[] = res.Data.List.map((item: any) => createWorkflowTree(item))
                    setWorkflowTree(data)
                }
            })
    }

    // 获取组织机构及用户信息
    const getOriginatorTree = () => {
        post({
            url: 'BpmQuery/GetOrgAndUserTree',
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data: OriginatorItem[] = res.Data.List.map((item: any) => createOriginatorTree(item))
                    setOriginatorTree(data)
                }
            })
    }

    return (
        <div className="workbench-page">
            {/* 左侧区域 */}
            <aside className="left-area">
                <ul className="sidebar-menu">
                    <li
                        className={pathname === '/workbench' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench')}
                    >工作看板</li>
                    <li
                        className={pathname === '/workbench/MyWorkflow' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyWorkflow')}
                    >发起流程</li>
                    <li
                        className={pathname === '/workbench/MyUnfinishedWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyUnfinishedWorkItem')}
                    >待办任务</li>
                    <li
                        className={pathname === '/workbench/MyFinishedWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyFinishedWorkItem')}
                    >已办任务</li>
                    <li
                        className={pathname === '/workbench/MyUnReadWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyUnReadWorkItem')}
                    >待阅任务</li>
                    <li
                        className={pathname === '/workbench/MyReadWorkItem' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyReadWorkItem')}
                    >已阅任务</li>
                    <li
                        className={pathname === '/workbench/MyInstance' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyInstance')}
                    >我的流程</li>
                    {/* <li
                        className={pathname === '/workbench/MyContract' ? "menu-item active" : "menu-item"}
                        onClick={() => navigate('/workbench/MyContract')}
                    >我的合同</li> */}
                </ul>
            </aside>

            {/* 右侧区域 */}
            <main className="right-area">
                <Suspense fallback={<Spin tip="加载中..." />}>
                    <Routes>
                        <Route index element={<WorkBoardPage originatorTreeData={originatorTree} />} />
                        <Route path="MyWorkflow" element={<MyWorkflowPage workflowTreeData={workflowTree} />} />
                        <Route path="MyUnfinishedWorkItem" element={<MyUnfinishedWorkItemPage workflowTreeData={workflowTree} />} />
                        <Route path="MyFinishedWorkItem" element={<MyFinishedWorkItemPage workflowTreeData={workflowTree} />} />
                        <Route path="MyUnReadWorkItem" element={<MyUnReadWorkItemPage workflowTreeData={workflowTree} />} />
                        <Route path="MyReadWorkItem" element={<MyReadWorkItemPage workflowTreeData={workflowTree} />} />
                        <Route path="MyInstance" element={<MyInstancePage workflowTreeData={workflowTree} />} />
                        <Route path="MyContract" element={<MyContractPage />} />
                        <Route path="*" element={<Navigate to='/' />} />
                    </Routes>
                </Suspense>
            </main>
        </div>
    )
}