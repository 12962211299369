import { useState } from 'react';
import { Modal } from 'antd';
import './index.less'

type PropsType = {
    data: {
        visible: boolean
        url: string
    }
    closeModal: () => void
}

export default ({ data, closeModal }: PropsType) => {
    const [isFullScreen, setIsFullScreen] = useState(false); // 是否全屏

    return (
        <Modal
            className={isFullScreen ? 'fullscreen' : ''}
            data-component-name="notice-modal"
            title={(
                <>
                    <h3>详情</h3>
                    <span
                        className={isFullScreen ? 'fm-icon-minimize fullscreen-btn' : 'fm-icon-maximize fullscreen-btn'}
                        onClick={() => setIsFullScreen(!isFullScreen)}
                    ></span>
                </>
            )}
            visible={data.visible}
            footer={null}
            centered
            onCancel={closeModal}
        >
            <iframe
                src={data.url}
                scrolling="auto"
                frameBorder={0}
            ></iframe>
        </Modal >
    )
}