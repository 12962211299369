import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../store/hook'
import { delAuthInfo, delUserInfo, setAuthInfo } from '../store/slices/currentUser'
import './App.less';
import { post } from '../utils/axios'
import qs from 'qs';

// 初始布局
import IndexLayout from '../views/pages/Index';
// 登录页面
import LoginPage from '../views/pages/Login';

export default () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch()
  const authInfo = useAppSelector(state => state.currentUser.authInfo)
  const subTokens: string[] = []
  const timers: NodeJS.Timer[] = []
  const [bpm, setBpm] = useState<string>();

  useEffect(() => {
    /**
     * 消息订阅
     * 
     * - 用户登录
     * - 用户退出
     * - 刷新登录 token
     * - 添加提醒
     */
    subTokens.push(PubSub.subscribe('UserLoginEvent', pageInitialize))
    subTokens.push(PubSub.subscribe('UserLogoutEvent', userLogout))
    subTokens.push(PubSub.subscribe('RefreshAuthInfoEvent', refreshAuthInfo))
    // 检查用户登录
    checkUserToken()
    return () => {
      /**
       * 退订所有消息
       */
      for (let i = subTokens.length - 1; i >= 0; i--) {
        PubSub.unsubscribe(subTokens[i])
        subTokens.pop()
      }
    }
  }, []);

  // 检查用户登录
  const checkUserToken = () => {
    if (authInfo?.token) {
      // 用户已登录, 跳转到首页界面
      if (pathname === '/login') {
        const { autologin } = qs.parse(search.slice(1))
        autologin != 'false' ? navigate('/') : navigate('/login')
      }
      // 页面初始化
      pageInitialize()
    } else {
      // 用户未登录, 跳转到登录界面
      if (pathname !== '/login') {
        navigate('/login')
      }
    }
  }

  // 页面初始化
  const pageInitialize = () => {
    loginBpm()
    /**
     * 定时器
     * 
     * - 定时更新BPM
     */
    timers.push(setInterval(loginBpm, 20 * 1000 * 60))
  }

  // 登录 BPM
  const loginBpm = () => {
    post({ url: 'Login/GetBpmSystemList' }, true)
      .then(res => {
        if (res.IsSuccess) {
          const [{ tempUrl }] = res.Data
          setBpm(tempUrl)
        }
      })
  }

  // 刷新登录 token
  const refreshAuthInfo = (msg: string, data: any) => {
    dispatch(setAuthInfo({
      token: data.token,
      time: data.time,
    }))
  }

  // 用户退出门户
  const userLogout = () => {
    setBpm(undefined)
    // 清除所有定时器
    for (let i = timers.length - 1; i >= 0; i--) {
      clearInterval(timers[i])
      timers.pop()
    }
    dispatch(delAuthInfo())
    dispatch(delUserInfo())

    navigate('/login')
  }

  return (
    <div className="app">
      <Routes>
        <Route path="/*" element={<IndexLayout />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<Navigate to='/' />} />
      </Routes>

      <iframe src={bpm} style={{ "display": "none" }}></iframe>
    </div>
  );
};