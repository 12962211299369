import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import "./index.less"
import { post } from "../../../../utils/axios"

type ReportItem = {
    ModuleEntityId: string
    ModuleName: string
    ModuleTargetType: number
    UrlAddress?: string
    children?: ReportItem[]
}

const createReportTree = (item: ReportItem): ReportItem => {
    if (item.ModuleTargetType === 1) {
        return {
            ModuleEntityId: item.ModuleEntityId,
            ModuleName: item.ModuleName,
            ModuleTargetType: 1,
            children: item.children?.map(node => createReportTree(node)),
        }
    } else {
        return {
            ModuleEntityId: item.ModuleEntityId,
            ModuleName: item.ModuleName,
            ModuleTargetType: 2,
            UrlAddress: item.UrlAddress
        }
    }
}

export default () => {
    const [reportMenuTree, setReportMenuTree] = useState<ReportItem[]>([])

    useEffect(() => {
        getReportMenuTree()
    }, [])

    // 获取 BPM 报表信息树
    const getReportMenuTree = () => {
        post({
            url: 'BpmReport/GetReportMenuTree',
        })
            .then(res => {
                if (res.IsSuccess) {
                    const data: ReportItem[] = res.Data.List.map((item: any) => createReportTree(item))
                    setReportMenuTree(data)
                }
            })
    }

    return (
        <div className="report-page">
            <Row gutter={20}>
                {
                    reportMenuTree.map(item => (
                        <Col 
                            key={item.ModuleEntityId}
                            className="h-margin-b-20" 
                            span={8}
                        >
                            <div className="report-list">
                                <h3 className="header">
                                    <div className="title">{item.ModuleName}</div>
                                </h3>
                                <div className="body">
                                    {
                                        item.children?.map(childrenItem => (
                                            <div 
                                                key={childrenItem.ModuleEntityId}
                                                className="report-item"
                                                onClick={() => window.open(childrenItem.UrlAddress, '_blank')}
                                            >{childrenItem.ModuleName}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}